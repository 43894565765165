import axios from 'axios'
import getThemeConfig from '../../providers/config/themeConfig'
import { getClientId } from '../../services/tracking'

const {
  videoPlatform: { feedbackForm },
} = getThemeConfig()

const prepareData = ({
  applicationId,
  rating,
  writtenFeedback,
  user,
  entryId,
}) => {
  return {
    Email: user.email,
    ClientId: getClientId(),
    EventId: entryId,
    RatingValue: parseInt(rating, 10),
    Comment: writtenFeedback,
    ApplicationId: applicationId.toString(),
  }
}

export const submitFormIsSuccess = async (formValues) => {
  const apiEndpoint =
    process.env.GATSBY_ENV === 'production'
      ? feedbackForm.prod
      : feedbackForm.staging

  const preparedFormFields = prepareData(formValues)

  return axios
    .post(apiEndpoint, preparedFormFields)
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}

export default submitFormIsSuccess
