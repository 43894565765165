import styled from '@emotion/styled'
import { Link } from 'ascential-horizon'

export const SpeakerText = styled('p')(() => ({
  margin: '0',
}))

export const SpeakerLink = styled(Link)(() => ({
  span: {
    borderBottom: 'none',
  },
}))

export default SpeakerLink
