import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'ascential-horizon'
import { submitFormIsSuccess } from './submitForm'
import {
  StyledFeedbackForm,
  StyledSelectField,
  StyledFormLabel,
  StyledTextArea,
  StyledRatingWrapper,
  StyledFurtherCommentBox,
} from './FeedbackForm.style'

export const FeedbackForm = ({ applicationId, entryId, user }) => {
  const [rating, setRating] = useState()
  const [writtenFeedback, setWrittenFeedback] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(null)

  const sendForm = async () => {
    setIsSubmitting(true)

    const formValues = { applicationId, rating, writtenFeedback, user, entryId }
    const formSentSuccessfully = await submitFormIsSuccess(formValues)

    setIsSuccess(formSentSuccessfully)
    setIsSubmitting(false)
  }

  if (isSuccess) {
    return <div data-test="feedback-message">Thank you for your feedback</div>
  }

  return user.email ? (
    <>
      <StyledFeedbackForm id="ratingWrapper">
        <StyledRatingWrapper>
          <StyledFormLabel htmlFor="rating-picker">
            What did you think of this session?
            <StyledSelectField
              defaultValue="no-value"
              id="rating-picker"
              onChange={(e) => {
                setRating(e.target.value)
              }}
              required
              value={rating}>
              <option value="no-value" disabled>
                Pick a rating
              </option>
              <option value="1">1 star</option>
              <option value="2">2 stars</option>
              <option value="3">3 stars</option>
              <option value="4">4 stars</option>
              <option value="5">5 stars</option>
            </StyledSelectField>
          </StyledFormLabel>
        </StyledRatingWrapper>

        <StyledFurtherCommentBox>
          <StyledFormLabel htmlFor="feedback-box" className="feedback-box">
            Any further comments?
            <StyledTextArea
              id="feedback-box"
              value={writtenFeedback}
              onChange={(e) => {
                setWrittenFeedback(e.target.value)
              }}
            />
          </StyledFormLabel>
        </StyledFurtherCommentBox>

        <Button
          disabled={isSubmitting}
          variant="secondary"
          inverted
          onClick={(e) => {
            e.preventDefault()
            if (isSubmitting) {
              return
            }
            sendForm()
          }}>
          {isSubmitting ? 'Submiting...' : 'Submit'}
        </Button>
      </StyledFeedbackForm>

      {isSuccess === false && !isSubmitting && (
        <div data-test="feedback-message">
          Sorry, something went wrong. Please try again
        </div>
      )}
    </>
  ) : (
    <div data-test="not-logged-in">
      Once you are logged in, you can rate this session
    </div>
  )
}

FeedbackForm.propTypes = {
  applicationId: PropTypes.number,
  entryId: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
}

FeedbackForm.defaultProps = {
  applicationId: 1,
  entryId: '',
  user: {
    email: '',
  },
}

export default FeedbackForm
