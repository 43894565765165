import React from 'react'
import PropTypes from 'prop-types'
import { ComponentWrapper, PageTitle, TextBlock } from 'ascential-horizon'
import { generateSlug } from '../../utils/slugifier'
import { SpeakerLink, SpeakerText } from './EventTextBlock.style'
import { trackLinkClick } from '../../services/tracking'

const EventTextBlock = ({
  banner,
  category,
  heading,
  description,
  showSpeakers,
  speakers,
  speakerLabel,
}) => {
  const trackSpeakerClick = (speaker) =>
    trackLinkClick(`${speaker.firstName} ${speaker.lastName}`, speaker.slug)

  const speakersLinks = []
  const categorySlug = `/category/${generateSlug(category)}`

  if (speakers && speakers.length > 0) {
    speakers.map((speaker, index) => {
      const speakerLink = (
        <SpeakerLink
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}-speakerLink`}
          to={speaker.slug}
          onClick={() => trackSpeakerClick(speaker)}>
          {`${index !== 0 ? ', ' : ' '}${speaker.firstName} ${
            speaker.lastName
          } ${speaker.company ? `- ${speaker.company}` : ''}`}
        </SpeakerLink>
      )

      speakersLinks.push(speakerLink)
      return speaker
    })
  }

  return (
    <>
      <ComponentWrapper columnWidth={12}>
        {heading && <PageTitle my="large">{heading}</PageTitle>}
        {showSpeakers && speakers && speakers.length > 0 && (
          <SpeakerText>
            {speakerLabel}: {speakersLinks}
          </SpeakerText>
        )}
        <SpeakerText>
          Category: <SpeakerLink to={categorySlug}>{category}</SpeakerLink>
        </SpeakerText>
      </ComponentWrapper>
      <TextBlock banner={banner} columnWidth={12} html={description} />
    </>
  )
}

EventTextBlock.propTypes = {
  banner: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    image: PropTypes.object,
    label: PropTypes.string,
  }),
  category: PropTypes.string,
  description: PropTypes.string,
  heading: PropTypes.string,
  showSpeakers: PropTypes.bool,
  speakerLabel: PropTypes.string,
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
}

EventTextBlock.defaultProps = {
  banner: {},
  category: '',
  description: '',
  heading: '',
  showSpeakers: true,
  speakerLabel: '',
  speakers: [],
}

export default EventTextBlock
