import React from 'react'
import PropTypes from 'prop-types'
import { MediaBlock } from 'ascential-horizon'
import EventTextBlock from '../EventTextBlock'
import LoginOverlay from '../LoginOverlay'
import { trackVideo } from '../../services/tracking'
import getThemeConfig from '../../providers/config/themeConfig'

function VideoType({
  category,
  location,
  showPlayer,
  speakers,
  subtitle,
  text,
  title,
  video,
  videoLength,
}) {
  const {
    videoPlatform: { videoOverlay },
  } = getThemeConfig()

  const talentsList = speakers
    .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
    .join(', ')

  const hasVideo = showPlayer && video && video.length > 0

  const subtitleTrack = subtitle && {
    tracks: [
      {
        label: 'English',
        kind: 'subtitles',
        src: subtitle,
        srcLang: 'en',
        default: true,
      },
    ],
  }
  const videoProps = hasVideo && {
    video: {
      url: video,
      aspectRatio: '16:9',
      progressInterval: 1000 * 10, // check progress every  10 sec
      onPause: () =>
        trackVideo(title, 'pause', category, talentsList, videoLength),
      onProgress: (e) => {
        if (e && e.played !== 0) {
          trackVideo(title, e.played, category, talentsList, videoLength)
        }
      },
      onStart: () => trackVideo(title, 0, category, talentsList, videoLength),
      config: {
        file: {
          ...subtitleTrack,
          attributes: {
            crossOrigin: 'true',
            controlsList: 'nodownload',
            onContextMenu: (e) => e.preventDefault(),
          },
        },
      },
    },
  }

  return (
    <>
      {hasVideo && (
        <LoginOverlay {...videoOverlay} location={location}>
          <MediaBlock {...videoProps} columnWidth={12} />
        </LoginOverlay>
      )}
      <EventTextBlock {...text} category={category} />
    </>
  )
}

VideoType.propTypes = {
  video: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  subtitle: PropTypes.string,
  videoLength: PropTypes.number,
  title: PropTypes.string,
  category: PropTypes.string,
  text: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
  }),
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hostname: PropTypes.string,
  }).isRequired,
  showPlayer: PropTypes.bool.isRequired,
}
VideoType.defaultProps = {
  video: null,
  subtitle: null,
  videoLength: 0,
  title: null,
  category: null,
  speakers: [],
  text: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
  }),
}

export default VideoType
