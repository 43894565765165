import styled from '@emotion/styled'

export const StyledFeedbackForm = styled('form')(({ theme: { space } }) => ({
  width: '100%',
  padding: `${space.small} 0px`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
}))

export const StyledSelectField = styled('select')(
  ({ theme: { space, mediaQueries, colors } }) => ({
    borderRight: `7px solid ${colors.black}`,
    height: space.xxlarge,
    width: '100%',
    paddingLeft: space.medium,
    display: 'block',

    [mediaQueries.medium]: {
      width: '95%',
    },
  })
)

export const StyledFormLabel = styled('label')(({ theme: { space } }) => ({
  fontWeight: 'bold',
  display: 'block',
  margin: `${space.medium} 0px`,
  width: '100%',
}))

export const StyledTextArea = styled('textarea')(
  ({ theme: { colors, space } }) => ({
    width: '100%',
    resize: 'none',
    borderRight: `7px solid ${colors.black}`,
    height: space.xxlarge,
    display: 'block',
  })
)

export const StyledRatingWrapper = styled('div')(
  ({ theme: { mediaQueries } }) => ({
    flexGrow: '1',
    width: '100%',

    [mediaQueries.medium]: {
      width: '40%',
    },
  })
)

export const StyledFurtherCommentBox = styled('div')(
  ({ theme: { mediaQueries } }) => ({
    flexGrow: '4',
    width: '100%',

    [mediaQueries.medium]: {
      width: '60%',
    },
  })
)

export default StyledFeedbackForm
