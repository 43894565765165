import styled from '@emotion/styled'
import { Column } from 'ascential-horizon'

export const BtnWrapper = styled(Column)(
  ({ theme: { space, mediaQueries } }) => ({
    paddingBottom: space.small,
    [mediaQueries.untilLarge]: {
      paddingLeft: space.small,
      paddingRight: space.small,
    },
  })
)

export default BtnWrapper
