import React from 'react'
import PropTypes from 'prop-types'
import { Container, Grid, Button } from 'ascential-horizon'
import EventTextBlock from '../EventTextBlock'
import { trackBtnClick } from '../../services/tracking'
import { renderImageBlock } from '../../utils/renderers'
import { BtnWrapper } from './assetType.style'

function AssetType({ assets, image, text, title, user: { loggedIn, login } }) {
  function handleClick(e, label, url) {
    trackBtnClick(label, url)
    if (!loggedIn) {
      e.preventDefault()
      login()
    }
  }

  return (
    <>
      {assets.length > 0 &&
        renderImageBlock(
          { size: 'xlarge', image: { file: { url: image } } },
          0
        )}
      <EventTextBlock {...text} />
      {assets.length > 0 && (
        <Container columnWidth={12}>
          <Grid>
            {assets.length > 0 &&
              assets.map(({ assetId, assetUrl, assetType }) => (
                <BtnWrapper key={assetId}>
                  <Button
                    variant="secondary"
                    onClick={(e) =>
                      handleClick(e, assetType, `${title} - ${assetUrl}`)
                    }
                    url={assetUrl}
                    target="_blank">
                    {assetType}
                  </Button>
                </BtnWrapper>
              ))}
          </Grid>
        </Container>
      )}
    </>
  )
}

AssetType.propTypes = {
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      assetId: PropTypes.number,
    })
  ),
  image: PropTypes.string,
  user: PropTypes.shape({
    loggedIn: PropTypes.bool,
    login: PropTypes.func,
  }),
  text: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
  }),
  title: PropTypes.string,
}
AssetType.defaultProps = {
  assets: [],
  user: {
    loggedIn: false,
    login: () => {},
  },
  image: '',
  text: null,
  title: '',
}

export default AssetType
