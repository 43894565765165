import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  Billboard,
  ComponentWrapper,
  Spacer,
  TextBlock,
  Stack,
} from 'ascential-horizon'
import { useIdentity } from '../providers/identityContext'
import getThemeConfig from '../providers/config/themeConfig'
import Layout from '../Layout'
import Breadcrumbs from '../components/Breadcrumbs'
import { Divider } from '../components/Divider'
import { FeedbackForm } from '../components/FeedbackForm'
import TaxonomyCarousels from '../components/PortalCarousels/taxonomy'
import Assets from '../components/TemplateEventTypes/assetType'
import VideoType from '../components/TemplateEventTypes/videoType'

const TemplateEventPage = ({ data, pageContext, location }) => {
  const { isAuthorized, loggedIn, login, user } = useIdentity()
  const {
    videoPlatform: { feedbackForm, hasPayWall, pagePrefix },
    speakers: { labelSingular, labelPlural },
    showBreadcrumbs,
  } = getThemeConfig()

  const [showPlayer, setShowPlayer] = useState(true)
  useEffect(() => {
    if (!hasPayWall) {
      return
    }

    if (!loggedIn) {
      login()
    }

    if (!isAuthorized) {
      setShowPlayer(false)
    }
  }, [])

  const {
    footerNav,
    header,
    headerAuth,
    home: { footer },
  } = data
  const {
    alternativeVideoSrc,
    assets,
    breadcrumb: { crumbs },
    category,
    categoryIsPartner,
    date,
    description,
    entryId,
    imageSrc,
    speakers,
    sponsorImage,
    streamId,
    subtitleSrc,
    title,
    videoLength,
    videoSrc,
  } = pageContext

  const showFeedbackForm = feedbackForm.showForm

  const subText =
    date && `<span>${categoryIsPartner ? '' : 'First aired: '}${date}</span>`

  const {
    footer: { type },
    header: { variation },
  } = getThemeConfig()

  const getHeaderProps = () => {
    if (user?.loading) {
      return <></>
    }
    const headerProps =
      variation === 'nav'
        ? {
            ...(loggedIn && headerAuth ? headerAuth : header),
          }
        : {
            ...data.home.header,
          }
    return headerProps
  }

  const footerProps = type === 'menu' ? footerNav : footer

  const layoutProps = {
    header: getHeaderProps(),
    footer: footerProps,
    pageContext,
    pageType: pagePrefix,
    location,
  }

  const bannerProps = sponsorImage
    ? {
        label: 'Sponsored by',
        textSize: 'large',
        image: {
          src: sponsorImage,
        },
      }
    : null

  const textProps = {
    heading: title,
    banner: bannerProps,
    description,
    showSpeakers: ![1650, 1654].includes(streamId), // TODO Hack to hide the speakers links for archived version of MoneyFest, refactor if the functionality needed for different shows, otherwise remove post MoneyFest2
    speakers,
    speakerLabel:
      speakers && speakers.length === 1 ? labelSingular : labelPlural,
  }

  // Removing second Crumb as the Events page does not exist
  crumbs.splice(pagePrefix ? 2 : 1, 1)

  const breadcrumbProps = {
    crumbs,
    crumbLabel: title,
    location,
  }
  const videoList = alternativeVideoSrc
    ? [videoSrc, alternativeVideoSrc]
    : videoSrc

  const billboardCopy = `<p>It only takes a moment and grants you total access to all of Cannes Lions Live.</p>`

  return (
    <Layout {...layoutProps}>
      {showBreadcrumbs && <Breadcrumbs {...breadcrumbProps} />}
      <Spacer space="large">
        <Stack space="small">
          {assets.length === 0 && (
            <VideoType
              category={category}
              location={location}
              speakers={speakers}
              text={textProps}
              title={title}
              video={videoList}
              videoLength={videoLength}
              subtitle={subtitleSrc}
              showPlayer={showPlayer}
            />
          )}
          {assets.length > 0 && (
            <Assets
              assets={assets}
              image={imageSrc}
              text={textProps}
              title={title}
              user={{ loggedIn, login }}
            />
          )}
          <TextBlock html={subText} columnWidth={12} />
          {showPlayer && showFeedbackForm && videoSrc && (
            <>
              <Divider />
              <ComponentWrapper columnWidth={[12]}>
                <FeedbackForm
                  applicationId={streamId}
                  entryId={entryId}
                  user={user}
                />
              </ComponentWrapper>
              <Divider />
            </>
          )}
        </Stack>
        <Stack space="small">
          <TaxonomyCarousels
            excludeIds={entryId}
            showOnly={[category]}
            taxonomy="category"
            title={`More From ${category}`}
          />
        </Stack>
      </Spacer>
      {!isAuthorized && hasPayWall && (
        <Billboard
          title="You need to buy a pass or become a Member to view that."
          subtitle={billboardCopy}
          button={{
            label: 'Buy now',
            url: 'https://www.lionscreativity.com/membership',
            icon: 'Join now',
          }}
        />
      )}
    </Layout>
  )
}

TemplateEventPage.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hostname: PropTypes.string,
  }).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export const templateEventPageQuery = graphql`
  query($rootId: String!) {
    header: contentfulNav(instanceName: { eq: "Main Menu" }) {
      ...navQuery
    }
    headerAuth: contentfulNav(instanceName: { eq: "Main Menu Auth" }) {
      ...navQuery
    }
    footerNav: contentfulFooter(instanceName: { eq: "Footer" }) {
      ...footerQuery
    }
    home: contentfulTemplateHomePage(id: { eq: $rootId }) {
      ...header
      footer {
        ...footerQuery
      }
    }
  }
`

export default TemplateEventPage
